// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-cookie-policy-template-js": () => import("./../src/templates/cookie-policy-template.js" /* webpackChunkName: "component---src-templates-cookie-policy-template-js" */),
  "component---src-templates-index-template-js": () => import("./../src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-privacy-policy-template-js": () => import("./../src/templates/privacy-policy-template.js" /* webpackChunkName: "component---src-templates-privacy-policy-template-js" */)
}

