module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-174201355-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"myclublist-website","short_name":"myclublist-website","start_url":"/","background_color":"#1a237e","theme_color":"#1a237e","display":"minimal-ui","icon":"src/images/logo.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ecffd0030e90b0efdb4e7c5fb9b81824"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
